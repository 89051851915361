<template>
    <div>
       <header>
         <div class="container-fluid">
            <div class="inner-header">
              <router-link to="/" class="logo"><img src="../assets/images/logo.svg" alt=""></router-link>
              <div class="menu-area">
                  <b-nav pills>
                    <b-nav-item><router-link to="/pricing">Prijzen</router-link></b-nav-item>
                    <b-nav-item><router-link to="#">Behandelingen</router-link></b-nav-item>
                    <b-nav-item>Winkel</b-nav-item>
                    <b-nav-item>Recensies</b-nav-item>
                    <b-nav-item>Contact</b-nav-item>
                  </b-nav>
              </div>
              <div class="log-in-are">
                <ul>
                  <li><a href="#">Inloggen</a></li>
                  <li><a href="#">Registreren</a></li>
                </ul>

                <a href="#" class="afspk-btn" ark="">AFSPRAAK MAKEN</a>
              </div>
            </div>
         </div>
       </header> 
    </div>
</template>

<script>
export default {
  name: 'Header',
  props: {
    msg: String
  }
}
</script>