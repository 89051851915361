<template>
  <div>
    <div class="top-four-bx">
        <div class="container">
            <div class="innerbody-sec">
                <div class="inner-bx">
                    <div class="inner-img-bx">
                        <img src="../assets/images/img1.jpg" alt="">
                    </div>
                </div>
                <div class="inner-bx">
                    <div class="inner-wht-txt-bx">
                    <h2>So Curly, een krullenspa. Ik hoor je denken een wat?</h2>
                    <h3>Nou lees even verder, ik zal het je uitleggen.</h3>
                    <a href="#" class="btn">Lees verder <img src="../assets/images/btnimg.png" alt=""></a>
                    </div>
                </div>
                <div class="inner-bx">
                    <div class="inner-img-bx">
                        <img src="../assets/images/img2.png" alt="">
                    </div>
                </div>
                <div class="inner-bx">
                    <div class="inner-wht-txt-bx inner-brown-bg">
                    <h2>Over So Curly</h2>
                    <p>So Curly is niet zomaar een kapsalon. Heb jij wavy, of curly hair? Dan ben jij bij mij aan het juiste adres. Na jaren ervaring als kapster en als curly ben ik volledig gespecialiseerd in krullen. Voor veel van jullie zal het concept helemaal  nieuw zijn. </p>
                    <a href="#" class="btn">Uniek? <img src="../assets/images/btnimg.png" alt=""></a>
                    </div>
                </div>
                
            </div>
        </div>
    </div>
    
  </div>
</template>

<script>
export default {
  name: 'TopFourBox',
  props: {
    msg: String
  }
}
</script> 