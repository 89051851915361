<template>
    <div class="footer">
       <footer class="footer">
            <div class="container">
                <div class="row justify-content-between">
                    <div class="col-lg-7">
                        <h2>Neem contact op</h2>
                        <div class="formHolder">
                            <form>
                                <div class="row">
                                    <div class="col-lg-6">
                                        <input type="text" placeholder="Naam" name="">
                                    </div>
                                    <div class="col-lg-6">
                                        <input type="email" placeholder="E-mail" name="">
                                    </div>
                                    <div class="col-lg-12">
                                        <textarea placeholder="Bericht"></textarea>
                                    </div>
                                    <div class="col-lg-12">
                                        <button type="submit">Versturen</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div class="col-lg-5">
                        <div class="footerRight">
                            <h2>Volg ons op sociale media</h2>
                            <div class="social">
                                <ul>
                                    <li><a href="#" target="_blank"> <img src="../assets/images/instagram.svg" alt=""> @socurly21</a></li>
                                    <li><a href="#" target="_blank"> <img src="../assets/images/facebook.svg" alt=""> @socurly21</a></li>
                                </ul>
                            </div>
                            <div class="copyright">© 2022 by So Curly. All rights served</div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    </div>
</template>

<script>
export default {
  name: 'Footer',
  props: {
    msg: String
  }
}
</script>