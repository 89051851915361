<template>
<div>
  <div class="sixbox">
        <div class="container">
            <div class="innerbody-sec">
                <div class="inner-bx">
                    <div class="inner-img-bx">
                        <img src="../assets/images/six-img1.png" alt="">
                    </div>
                </div>
                <div class="inner-bx">
                    <div class="inner-wht-txt-bx inner-brown-bg long-padding">
                        <h2>Openingstijden</h2>
                        <h3>Behandelingen alleen op afspraak</h3>
                        <div class="white-round-bx">
                            <ul>
                                <li><span>Maandag</span><span>9:00 - 17:00</span></li>
                                <li><span>Dinsdag</span><span>9:00 - 14:30</span></li>
                                <li><span>Woensdag</span><span>Gesloten</span></li>
                                <li><span>Donderdag</span><span>9:00 - 17:00</span></li>
                                <li><span>Vrijdag</span><span>9:00 - 14:30</span></li>
                                <li><span>Zaterdag</span><span>Gesloten</span></li>
                                <li><span>Zondag</span><span>Gesloten</span></li>
                            </ul>
                        </div>
                        <div class="text-center">
                            <a href="#" class="afspk-btn" ark="">AFSPRAAK MAKEN</a>
                        </div>
                    </div>
                </div>

                <div class="inner-bx">
                    <div class="inner-wht-txt-bx">
                    <h2>Zorg voor je kroon</h2>
                    <p>Iedere behandelingen is gebasseerd op persoonlijke aandacht en advies. Iedere krul is uniek en ik leer jou tijdens de behandeling graag hoe je daar het maximale uit kunt halen.  </p>
                    <a href="#" class="btn">Prijslijst <img src="../assets/images/btnimg.png" alt=""></a>
                    </div>
                </div>
                
                 <div class="inner-bx">
                    <div class="inner-img-bx">
                        <img src="../assets/images/six-img3.png" alt="">
                    </div>
                </div>
                 <div class="inner-bx">
                    <div class="inner-img-bx">
                        <img src="../assets/images/six-img4.png" alt="">
                    </div>
                </div>
                
                <div class="inner-bx">
                    <div class="inner-wht-txt-bx inner-brown-bg">
                    <h2>Ontspannen</h2>
                    <p>Een unieke combinatie van persoonlijke aandacht, krullenadvies, ontspanning, een spa herstel- of detoxbehandeling en met een goede bos krullen de deur weer uit.  </p>
                    
                    <a href="#" class="btn">Behandelingen <img src="../assets/images/btn-img2.png" alt=""></a>
                    </div>
                </div>
               
               
                
            </div>
        </div>
  </div>
</div>
  
</template>
<script>
export default {
  name: 'SixBox',
  props: {
    msg: String
  }
}
</script>