<template>
  <div class="home">
    <TopFourBox/>
    <div class="single-box">
      <div class="container">
        <h2>Wat maakt So Curly uniek?</h2>
        <p>Een 1:1 afspraak waarin ik alle tijd voor je heb om samen te kijken wat er mogelijk is met jouw krullen. Een persoonlijk advies gesprek waar ik jou help jouw krullen te omarmen en het meeste uit jouw krullen te halen.</p>

        <p>Een heerlijk wellness moment bij de wasbak. Niet zomaar een wasbak, maar een wasbak met massagefunctie waardoor je heerlijk ontspannen ligt. De mist functie zorgt dat de voedingsstoffen diep inwerken in jouw haar. En als kers op de taart krijg je nog een heerlijke hoofdhuid massage.</p>

        <p>Krullen vragen om een goed model. Met speciale krullen kniptechnieken brengen we dit model in jouw haar. Met de diffuser stylen we jouw haar tot het juiste model. Tijdens het stylen krijg je natuurlijk gelijk tips mee om jouw haar ook zelf in model te kunnen brengen. </p>
        <h3>Kan je niet wachten? Neem dan contact met mij op om een afspraak te maken. </h3>
        <h4>~Liefs, Roos</h4>
      </div>
    </div>
    <SixBox/>
    <div class="before-footer-sec">
      <div class="container">
        <div class="section4">
            <div class="sec4Box">
                <div class="row align-items-center">
                    <div class="col-lg-6 order-lg-2 hover14">
                        <figure><img src="../assets/images/homeimage6.svg" alt=""></figure>
                    </div>
                    <div class="col-lg-6">
                        <div class="sec4Cn">
                            <h2>Het beste voor jouw krullen</h2>
                            <p>Alleen het allerbeste voor jouw haar. Curly Girl Approved en Vegan. Bestel het direct direct.</p>
                            <div class="certiSectionFt">
                                <div class="certiname">
                                    <h3><a href="#">Winkel</a></h3>
                                </div>
                                <div class="certiIcon">
                                    <img src="../assets/images/certi1.png" alt="">
                                    <img src="../assets/images/certi2.png" alt="">
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
        <div class="section5">
            <div class="sec5Inner">
                <div class="appoText">Klaar om aan jouw krullen reis te beginnen?</div>
                <div class="appoBtn">
                    <a data-bs-toggle="modal" data-bs-target="#appoModal" class="btnAf">AFSPRAAK MAKEN</a>
                </div>
            </div>
        </div>
        <div class="section6">
            <div class="divider">
                <img src="../assets/images/divider.svg" alt="">
            </div>
            <div class="sectionHeading">
                <h2>Recensies</h2>
            </div>
            <div class="testiHolder">
                <div class="row">
                    <div class="col-lg-4">
                        <div class="testiBox">
                            <div class="startrating">
                                <span class="fa fa-star checked"></span>
                                <span class="fa fa-star checked"></span>
                                <span class="fa fa-star checked"></span>
                                <span class="fa fa-star"></span>
                                <span class="fa fa-star"></span>
                            </div>
                            <h3>Mijn haar zit super!</h3>
                            <p>Heel blij dat ik bij Roos geweest ben, heerlijke spa behandeling en goeie tips gekregen!</p>
                            <h4>~Annemieke</h4>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <div class="testiBox">
                            <div class="startrating">
                                <span class="fa fa-star checked"></span>
                                <span class="fa fa-star checked"></span>
                                <span class="fa fa-star checked"></span>
                                <span class="fa fa-star"></span>
                                <span class="fa fa-star"></span>
                            </div>
                            <h3> Een brede glimlach!</h3>
                            <p>Wat een super fijne sfeer in de salon. Helemaal blij met het resultaat. En ook een heerlijk moment voor jezelf.</p>
                            <h4>~ Natasja</h4>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <div class="testiBox">
                            <div class="startrating">
                                <span class="fa fa-star checked"></span>
                                <span class="fa fa-star checked"></span>
                                <span class="fa fa-star checked"></span>
                                <span class="fa fa-star"></span>
                                <span class="fa fa-star"></span>
                            </div>
                            <h3>Roos weet echt wat ze doet!</h3>
                            <p>Na twee behandelingen is mijn haar al enorm beter geworden. Kan nu eindelijk mijn krullen laten zoals ze zijn, zonder pluis.</p>
                            <h4>~Bernadette</h4>
                        </div>
                    </div>
                </div>
            </div>
            <div class="alltesti text-center">
                <h3><a href="#">Bekijk meer</a></h3>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
//import HelloWorld from '@/components/HelloWorld.vue'
import TopFourBox from '../components/top-four-box.vue'
import SixBox from '../components/six-box.vue'
export default {
  name: 'Home',
  components: {
    TopFourBox,
    SixBox
    }
}
</script>
